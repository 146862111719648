/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import PropTypes from "prop-types"

import NavBar from "./common/NavBar"
import Copyright from "./common/CopyRight"

import styled from "styled-components"

const PageContainer = styled("main")`
  position: relative;
  min-height: 100vh;
  background-color: white;
`

const ContainerWrap = styled("main")`
  padding-bottom: 20px;
`

const Footer = styled("main")`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const Layout = ({ children }) => {
  return (
    <>
      <PageContainer>
        <ContainerWrap>
          <NavBar />
          <main>{children}</main>
        </ContainerWrap>
        <Footer>
          <Copyright />
        </Footer>
      </PageContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
