import React from "react"
import Layout from "../components/layout"
import { Grid, Typography, Link, Box, Container } from "@mui/material"
import Seo from "../components/seo"

const rootStyle = {
  display: "flex",
  flexWrap: "wrap",
  padding: "30px",
  bgcolor: "white",
}

const sectionStyle = {
  padding: "30px",
  borderRadius: "10px",
  backgroundColor: "rgb(250,250,250)"
}

const ContactUS = () => {
  return (
    <>
      <Seo title="Contact" />
      <Layout>
        <Box sx={rootStyle}>
          <Container>
          <Typography variant="h4" align= "center" gutterBottom="true"s> Contact</Typography>
            <Box marginTop={10}>
              <Grid container justifyContent="center" spacing={6}>
                <Grid item xs={12} sm={5}>
                  <Box sx={sectionStyle}>
                    <Typography variant="h4" gutterBottom="true">
                      Business
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      paragraph="true"
                      gutterBottom="true"
                    >
                      If you are interested to know more about the product, please
                      write to,{" "}
                      <Link href="mailto:sales@nungu.ai">sales@nungu.ai</Link>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box sx={sectionStyle}>
                    <Typography variant="h4" gutterBottom="true">
                      Technical Support
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      paragraph="true"
                      gutterBottom="true"
                    >
                      If you have any technical queries related to the product or
                      you want to report bugs in the project, please write to,{" "}
                      <Link href="mailto:support@nungu.ai">support@nungu.ai</Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Layout>
    </>
  )
}

export default ContactUS
